.logo {
  width: 100px;
  height: auto;
}

.linktext {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}
@media only screen and (max-width: 600px) {
  .navLogoAndTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.nav-link {
  font-size: 1.5em;
}
.navbar {
  z-index: 10;
}
